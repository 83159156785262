.custom-scrollbar::-webkit-scrollbar {
    width: 2px; /* Largeur de la barre de défilement verticale */
  }
 /* Style pour la scrollbar globale */
.custom-scrollbar {
  scrollbar-width: thin; /* Pour Firefox : utiliser une scrollbar mince */
  scrollbar-color: #afc7df #f0f0f0; /* Pour Firefox : couleur de la barre et de l'arrière-plan */
}

/* Style pour la scrollbar sur les navigateurs Webkit (Chrome, Safari) */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px; /* Largeur réduite de la scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f0f0f0; /* Couleur de l'arrière-plan de la scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #afc7df; /* Couleur de la barre de défilement */
  border-radius: 10px !important; /* Bordure arrondie de la barre de défilement */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #2980b9; /* Couleur de la barre de défilement au survol */
}

 
