.active {
  background-color: #ADD8E6; /* Light blue */
}

.inactive {
  background-color: #D3D3D3; /* Light gray */
}

/* Additional CSS to ensure proper alignment */
.surveillance-schedule table {
  border-collapse: collapse;
  width: 100%;
}

.surveillance-schedule th,
.surveillance-schedule td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.surveillance-schedule th {
  background-color: #f4f4f4;
}
/* Valeur de base pour les petits écrans */

.storage {
  margin-left: auto; 
  margin-right: 0; /* Ajuste cette valeur selon le décalage voulu */
  width: calc(100% - 820px);
}

/* Styles pour les grands écrans */
@media (min-width: 1200px) {
  .storage {
    margin-left: auto; 
    margin-right: 50px; /* Ajuste cette valeur pour grands écrans */
  }
}

/* Styles pour les écrans moyens */
@media (max-width: 1199px) {
  .storage {
    margin-left: auto; /* Pousse l'élément vers la droite */
    margin-right: 30px; /* Ajuste cette valeur pour écrans moyens */
  }
}

/* Styles pour les petits écrans */
@media (max-width: 767px) {
  .storage {
    margin-left: auto; /* Pousse l'élément vers la droite */
    margin-right: 10px; /* Ajuste cette valeur pour petits écrans */
  }
}

/* Ajustement pour des tailles spécifiques si nécessaire */
@media (max-width: 500px) { /* Ajuste cette valeur selon les besoins */
  .storage {
    margin-left: auto; /* Pousse l'élément vers la droite */
    margin-right: 5px; /* Ajuste cette valeur pour très petits écrans */
  }
}

.css-2ulfj5-MuiTypography-root {
 /* ou absolute si vous avez besoin de positionnement absolu */
  font-size: 1.5rem;
  margin-top: 20px;
margin-right: 200px;
letter-spacing: 0.0075em;

}

/* CameraDetails.css */

/* Styles généraux pour le conteneur */
.camera-details-container {
  padding: 20px;
}

/* Styles pour les écrans larges */
@media (min-width: 600px) {
  .camera-details-container {
    padding: 30px;
  }
  
  .app-bar {
    margin-left: 30px;
    margin-top: 12px;
    width: calc(100% - 262px);
  }

  .app-bar-title {
    text-align: left;
  }
}

/* Styles pour les écrans moyens à petits */
@media (max-width: 599px) {
  .camera-details-container {
    padding: 10px;
  }
  
  .app-bar {
    margin-left: 0;
    margin-top: 0;
    width: 100%;
  }

  .app-bar-title {
    text-align: center;
  }

  /* Ajustements supplémentaires pour les tableaux et autres éléments */
  .camera-details-table {
    font-size: 14px;
  }

  .camera-details-table th, .camera-details-table td {
    padding: 8px;
  }
}

/* Styles pour les écrans très petits */
@media (max-width: 400px) {
  .camera-details-container {
    padding: 5px;
  }

  .app-bar-title {
    font-size: 18px;
  }

  .camera-details-table th, .camera-details-table td {
    padding: 6px;
  }
}
