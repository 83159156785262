.container {
  display: block;
  margin: auto;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
  max-width: 580px;
  height: auto; /* Adjust height to auto for responsiveness */
  padding: 20px; /* Add padding to prevent content from touching edges */
}

.col-lg-4 {
  background-color: #ececec;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 2.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 0 auto; /* Center align the box */
}

h1 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

input {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

input:hover {
  border-color: #b8c2cc;
}

input:focus {
  outline: 0;
  border-color: #80bdff;
}

button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: rgb(52, 152, 219);
  color: #fff;
  border: 1px solid rgb(52, 152, 219);
  border-radius: 0.35rem;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
  display: inline-block;
}

button:hover {
  border-color:  #fff;
}

.error-message {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.form-group {
  margin-right: 24px;
}

.box {
  max-height: 600px; /* Adjusted to prevent overflow on smaller screens */
}

.col-12 {
  height: auto; /* Adjust height to auto for responsiveness */
  /* background: linear-gradient(178deg, rgba(201, 234, 252, 0.51) 14.9%, rgba(139, 192, 216, 0.73) 80%); */
 background-color: #e4f1fe;  
 /* background-color: #d5def5; */
}

.mb-3 {
  margin-top: 40px;
}

.small {
  margin-left: 0; /* Remove left margin on smaller screens */
}

.reset {
  font-size: 1rem;
  background-color: #007bff;
  color: #fff;
  border: 1px solid #007bff;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
  display: inline-block;
  margin-top: 40px;
}

/* Media Queries for responsiveness */
@media (max-width: 992px) {
  .container {
    margin-top: 100px; /* Adjust top margin for tablets and smaller devices */
  }
}

@media (max-width: 768px) {
  .container {
    margin-top: 50px; /* Adjust top margin for smartphones */
    max-width: 100%; /* Full width on smaller screens */
    padding: 10px; /* Reduce padding */
  }
  
  .col-lg-4 {
    padding: 1.5rem; /* Adjust padding for smaller screens */
  }

  .form-group {
    margin-right: 0; /* Remove right margin for smaller screens */
  }

  .small {
    margin-left: 0; /* Remove left margin on smaller screens */
  }

  .box {
    max-height: auto; /* Allow box height to adjust on smaller screens */
  }

  button {
    font-size: 0.875rem; /* Smaller font size for buttons on smaller screens */
  }
}
